<template>
  <div :class="rootClass">
    <icon-button
      @click="$emit('page', page - 1)"
      data-test="pagination-prev"
      icon="chevron_left"
      :disabled="page === 1"
    />
    <ul class="pages">
      <li
        v-for="p in numPages"
        :key="p"
        :class="{pagelink: true, 'pagelink--active': p === page}"
        @click="$emit('page', p)"
      >
        {{ p }}
      </li>
    </ul>
    <icon-button
      @click="$emit('page', page + 1)"
      data-test="pagination-next"
      icon="chevron_right"
      :disabled="page === numPages"
    />
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';

export default {
  components: {
    IconButton,
  },
  props: {
    page: { type: Number, default: 1 },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
  computed: {
    rootClass() {
      return {
        pagination: true,
      };
    },
    numPages() {
      return Math.ceil(this.total / this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
}

.pages {
  display: flex;
  margin: 0 16px;
}

.pagelink {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  & + & {
    margin-left: 4px;
  }

  &--active {
    background: var(--color-card-background);
  }
}
</style>
