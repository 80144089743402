<template>
  <div>
    <div class="header">
      <h1 class="title">{{ currentCategory }}</h1>
      <div class="header-actions">
        <InputText
          v-model="search"
          placeholder="Keyword Search"
          data-test="article-search"
        />
        <Button data-test="article-create" @click="create" state="success">+ Add new</Button>
      </div>
    </div>
    <fetch-data
      combine
      :get="{ articles: 'articles/type', attrs: { type, page, search } }"
    >
      <template #default="{ articles, articles_meta, _loading }">
        <table class="table">
          <tr class="table__row" v-for="article in articles" :key="article.id">
            <td>
              <router-link
                data-test="article-list-item"
                :to="{ name: 'article', params: { id: article.id } }"
                class="article-link"
              >
                {{ article.title || 'Untitled' }}
              </router-link>
            </td>
            <td>
              {{ new Date(article.updatedAt).toLocaleDateString('en-UK', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}}
            </td>
          </tr>
        </table>
        <div class="category-pagination">
          <Pagination
            v-if="!_loading && articles_meta"
            :page="articles_meta.page.current"
            :perPage="articles_meta.page.per_page"
            :total="articles_meta.page.total"
            @page="updatePage"
          />
        </div>
      </template>
    </fetch-data>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from '@/components/Button.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  components: {
    Button,
    Pagination,
    InputText,
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
    page() {
      return Number(this.$route.query.page) || 1;
    },
    categories() {
      return this.$store.getters['categories/all'];
    },
    currentCategory() {
      const category = this.categories.find((c) => c.key === this.type);
      return category ? category.label : '';
    },
    search: {
      get() {
        return this.$route.query.search || '';
      },
      set(q) {
        const query = {};
        if (q) query.search = q;
        this.$router.replace({
          name: 'category',
          params: { type: this.type },
          query,
        });
      },
    },
  },
  methods: {
    create() {
      this.$store.dispatch('articles/create', { title: '', type: this.type }).then(({ id }) => {
        this.$router.push({ name: 'article', params: { id } });
      });
    },
    updatePage(page) {
      const query = { page };
      if (this.search) query.search = this.search;
      this.$router.push({
        name: 'category',
        params: { type: this.type },
        query,
      });
    },
    rowClass() {
      return 'datatable-row';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-actions {
  display: flex;
}

.title {
  @include text-headline1;
}

.table {
  width: 100%;
}
.table__row {
  @include text-table;

  &:nth-child(2n) {
    background: var(--color-card-background);
  }

  td {
    padding: 16px 12px;
  }
}

.article-link {
  @include text-table-highlight;
  color: inherit;
}

.category-pagination {
  margin-top: 72px;
  display: flex;
  justify-content: center;;
}
</style>
